import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const loginFreezeSlice = createSlice({
  initialState,
  name: "loginFreeze",
  reducers: {
    setLoginFreeze: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setLoginFreeze } = loginFreezeSlice.actions;

export default loginFreezeSlice.reducer;
