import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const globalLoadingSlice = createSlice({
  initialState,
  name: "globalLoading",
  reducers: {
    setGlobalLoading: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setGlobalLoading } = globalLoadingSlice.actions;

export default globalLoadingSlice.reducer;
