import React from "react";
import { Modal, Spinner } from "reactstrap";
import styled from "styled-components";

const MiddleDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const Dimmer = (props: any) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={props.active}
        backdrop={true}
        keyboard={false}
        style={{ height: "100%" }}
        id="dimmi"
      >
        <MiddleDiv>
          <Spinner color="light"></Spinner>
          <div style={{ color: "white" }}>{props.label}</div>
        </MiddleDiv>
      </Modal>
    </React.Fragment>
  );
};

export default Dimmer;
