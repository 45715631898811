import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSubType {
  firstName?: string;
  lastName?: string;
  langArray?: string[];
  email?: string;
  language?: string;
  type?: string;
  [key: string]: any;
}

export type UserType = UserSubType | null;

const currentUserSlice = createSlice({
  initialState: null as UserType,
  name: "currentUser",
  reducers: {
    setCurrentUser: (state: UserType, action: PayloadAction<UserType>) => {
      return action.payload;
    },
  },
});

export const { setCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
