import "react-modal-video/css/modal-video.css";
import "./App.css";
import "@core/components/ripple-button";

import "react-semantic-toasts/styles/react-semantic-alert.css";
// import "@core/scss/core.scss";
import "@core/scss/base/plugins/extensions/ext-component-sweet-alerts.scss";

// import "nouislider/dist/nouislider.css";
import "@core/scss/react/libs/toastify/toastify.scss";

import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";

import AppStart from "./App.authCheck";

import Routing from "./App.routing";
import AppUpdating from "./App.updating";
import UpgradeChecker from "./App.upgradechecker";
import AppUrlListener from "./app.urlListener";
import AppFirstTime from "./routing/App.firstTime";

import SendIntentSetter from "./App.sendIntent";
import { checkGoing } from "home/App.loadWarnings";

// import Counter from "./App.counter";

import styled from "styled-components";
import { Button } from "reactstrap";
import TranslationService from "../@translations/TranslationService";
import Theming from "./App.theming";

import AppAuthStateHandling from "./App.authStateHanding";

import { StatusBar } from "@capacitor/status-bar";
import GlobalLoader from "./App.globalLoader";
import { Device } from "@capacitor/device";
import { ScreenDiv } from "./App.maincss";
import HomeDialog from "home/home.dialog";

StatusBar.setBackgroundColor({ color: "#2185d0" });

export let platform: any = "web";

try {
  Device.getInfo().then((i: any) => (platform = i.platform));
} catch (e) {
  console.log(e);
}

//@ts-ignore

//@ts-ignore

//@ts-ignore
export const ButtonRipple = styled(Button.Ripple)`
  &&&:active {
    background-color: initial;
  }
  &&&:focus {
    background-color: initial;
  }
  &&&.btn-primary:focus {
    background-color: var(--toolbar-background) !important;
  }
`;

export const IosHeaderDiv = styled.div`
  width: 100%;
  height: env(safe-area-inset-top);
  z-index: 50000;
`;

try {
  // window.screen.orientation.lock("portrait");
} catch (e) {}
export const isTouch = matchMedia("(hover: none)").matches;

// StatusBar.setStyle({ style: Style.Dark });

function App() {
  useEffect(() => {
    Device.getInfo().then((info: any) => {
      if (info.platform === "web") {
        checkGoing();
      }
    });
  }, []);

  return (
    <React.Fragment>
      <ScreenDiv>
        <IosHeaderDiv className="smoothcover" />
        <AppAuthStateHandling />
        <Theming />
        <TranslationService />
        <UpgradeChecker />
        <AppUrlListener />
        <Routing />
        <AppFirstTime />
        <AppStart />
        <SendIntentSetter />
        <AppUpdating />
        <GlobalLoader />
        <ToastContainer
          newestOnTop
          enableMultiContainer
          containerId={"default"}
        />
      </ScreenDiv>
      <HomeDialog />
    </React.Fragment>
  );
}

export default App;
