import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HelpType {
  open: any;
  content: any;
  tempSrc: string;
  header?: string;
}

const initialState: HelpType = {
  open: "",
  content: "",
  tempSrc: "cards.mp4",
  header: "",
};

const helpSlice = createSlice({
  initialState,
  name: "help",
  reducers: {
    setHelpContent: (state: HelpType, action: PayloadAction<any>) => {
      state.content = action.payload;
    },
    setHelpOpen: (state: HelpType, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
    setHelpTemp: (state: HelpType, action: PayloadAction<string>) => {
      state.tempSrc = action.payload;
    },
    setHelpHeader: (state: HelpType, action: PayloadAction<string>) => {
      state.header = action.payload;
    },
  },
});

export const { setHelpContent, setHelpHeader, setHelpOpen, setHelpTemp } =
  helpSlice.actions;

export default helpSlice.reducer;
