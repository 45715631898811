import React from "react";

import {
  HomeHelpBoldDiv,
  HomeHelpHeaderDiv,
  HomeWrapperDiv,
} from "./00-styles";

export default function HelpIPhoneWarn() {
  return (
    <React.Fragment>
      <HomeWrapperDiv>
        <HomeHelpHeaderDiv>
          Üdvözlünk a Gyakorlatias Angol alkalmazásában!
        </HomeHelpHeaderDiv>
        <HomeHelpBoldDiv>
          iPhone-on és iPaden ajánljuk, hogy a Safari böngészőben nyisd meg az
          alkalmazásunkat. Működhet más böngészőkben is, de lehet, hogy nem
          tudod kitenni a kezdőképernyőre az alkalmazás ikonját.
        </HomeHelpBoldDiv>
      </HomeWrapperDiv>
    </React.Fragment>
  );
}
