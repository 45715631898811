import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HomeType {
  open: boolean;
  src: any;
  tempSrc: string;
}

const initialState: HomeType = {
  open: false,
  src: "",
  tempSrc: "cards.mp4",
};

const homeSlice = createSlice({
  initialState,
  name: "home",
  reducers: {
    setHomeSrc: (state: HomeType, action: PayloadAction<any>) => {
      state.src = action.payload;
    },
    setHomeOpen: (state: HomeType, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setHomeTemp: (state: HomeType, action: PayloadAction<string>) => {
      state.tempSrc = action.payload;
    },
  },
});

export const { setHomeSrc, setHomeOpen, setHomeTemp } = homeSlice.actions;

export default homeSlice.reducer;
