import { doc, setDoc } from "firebase/firestore";
import store from "redux/store";

import { firestore, FreeData } from "./firebaseUtil";

// eslint-disable-next-line
Date.prototype.toJSON = function () {
  var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time
  var sign = timezoneOffsetInHours >= 0 ? "+" : "-";
  var leadingZero = Math.abs(timezoneOffsetInHours) < 10 ? "0" : "";

  //It's a bit unfortunate that we need to construct a new Date instance
  //(we don't want _this_ Date instance to be modified)
  var correctedDate = new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getDate(),
    this.getHours(),
    this.getMinutes(),
    this.getSeconds(),
    this.getMilliseconds()
  );
  correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
  var iso = correctedDate.toISOString().replace("Z", "");

  return (
    iso +
    sign +
    leadingZero +
    Math.abs(timezoneOffsetInHours).toString() +
    ":00"
  );
};

export const updateSetting = async (setting: FreeData) => {
  const currentUser = store.getState().currentUser;

  await setDoc(
    doc(firestore, `/users/${currentUser?.id}/data/settings`),
    setting,
    {
      merge: true,
    }
  );
};
