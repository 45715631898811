//student
import React from "react";
import { setConfirm } from "redux/reducers/reducer.confirm";
import store from "redux/store";

import { tx } from "./globalize";
import { setShowMain } from "redux/reducers/reducer.showMain";
import { setShowSub } from "redux/reducers/reducer.showSub";

export function isScrolledIntoView(el: Element | null) {
  if (!el) {
    return true;
  }
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  // Only completely visible elements return true:
  var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export const phaseBodyOut = () => {
  store.dispatch(setShowMain(false));
};

export const phaseBodyIn = () => {
  store.dispatch(setShowMain(true));
};

export const phaseOut = (div: string = "appmain") => {
  store.dispatch(setShowSub(false));
};

export const phaseIn = (div: string = "appmain") => {
  store.dispatch(setShowSub(true));
};

export function shuffleArray(arrayIn: any[]) {
  let array = [...arrayIn];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const beginsWith = (needle: string, arr: string | string[]) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  for (let g = 0; g < arr.length; g++) {
    if (needle.indexOf(arr[g]) === 0) {
      return true;
    }
  }
  return false;
};

export const contains = (needle: string, arr: string | string[]) => {
  if (typeof arr === "string") {
    arr = [arr];
  }
  for (let g = 0; g < arr.length; g++) {
    if (needle.indexOf(arr[g]) > -1) {
      return true;
    }
  }
  return false;
};

export const encodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&/g, "&amp;")
    .replace(/>/g, "gt;")
    .replace(/</g, "lt;");
};

export const decodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&nbsp;/g, " ");
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const showNoAppDialog = () => {
  store.dispatch(
    setConfirm({
      confirmButton: tx("ok", "OK"),
      onConfirm: () => {},
      header: tx("tdpv7yPTImcgweNye_", "Use the App Version..."),
      cancelButton: "",
      content: (
        <React.Fragment>
          <div>
            {tx(
              "aZSmswe4ri6tTzaoTLy",
              "Please download and use the app version of IntMenu for this function"
            )}
          </div>
        </React.Fragment>
      ),
      open: true,
    })
  );
};
