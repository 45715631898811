import React, { useEffect } from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  margin-top: 0px;
  padding: 15px;
  padding-top: 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
`;

const TextDiv = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 10px;
  line-height: 130%;
`;
const MainHeaderDiv = styled.div`
  font-size: 26px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
  color: var(--toolbar-background);
`;

const Impressum = (props: any) => {
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <WrapperDiv>
        <MainHeaderDiv>{tt("sst1acx", "About Us")}</MainHeaderDiv>
        <TextDiv>
          {tt(
            "t214a",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "t214aa",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </TextDiv>
        <TextDiv>{tt("t215a", "*Website:* https://netenglish.com")}</TextDiv>
        <TextDiv>{tt("t216a", "*Tax Number:* 27972984-2-18")}</TextDiv>
        <TextDiv>{tt("t217a", "*E-mail:* support@netenglish.com")}</TextDiv>
        <TextDiv>
          {tt("t218a", "*Company Registry Number:* 18-17-000025")}
        </TextDiv>
        <TextDiv>
          {tt("t218aw", "*Registered by:* Szombathely Company Court")}
        </TextDiv>
        <TextDiv>
          {tt(
            "t219a",
            "*Hosted by:* Google Cloud Hosting (support@google.com)<br/>Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA"
          )}
        </TextDiv>
      </WrapperDiv>
    </React.Fragment>
  );
};

export default Impressum;
