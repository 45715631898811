import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const showMainSlice = createSlice({
  initialState,
  name: "showMain",
  reducers: {
    setShowMain: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setShowMain } = showMainSlice.actions;

export default showMainSlice.reducer;
