import { combineReducers } from "@reduxjs/toolkit";
import activeMenuReducer from "./reducer.activeMenu";
import menusReducer from "./reducer.menus";
import appTransPartsReducer from "./reducer.appTransParts";
import authLoadedReducer from "./reducer.authLoaded";
import confirmReducer from "./reducer.confirm";
import currentUserReducer from "./reducer.currentUser";
import customizeReducer from "./reducer.customize";
import editedFileReducer from "./reducer.editedFile";
import editToolReducer from "./reducer.editTool";
import globalLoadingReducer from "./reducer.globalLoading";
import helpReducer from "./reducer.help";
import homeScreenReducer from "./reducer.homescreen";
import isChangedReducer from "./reducer.isChanged";
import isLoadingReducer from "./reducer.isLoading";
import loginFreezeReducer from "./reducer.loginFreeze";
import settingsReducer from "./reducer.settings";
import showMainReducer from "./reducer.showMain";
import showSubReducer from "./reducer.showSub";
import tempLanguageReducer from "./reducer.tempLanguage";
import updateOpenReducer from "./reducer.updateOpen";
import updatingReducer from "./reducer.updating";

const rootReducer = combineReducers({
  activeMenu: activeMenuReducer,
  appTransParts: appTransPartsReducer,
  authLoaded: authLoadedReducer,
  confirm: confirmReducer,
  currentUser: currentUserReducer,
  customize: customizeReducer,
  editedFile: editedFileReducer,
  editTool: editToolReducer,
  globalLoading: globalLoadingReducer,
  help: helpReducer,
  home: homeScreenReducer,
  isChanged: isChangedReducer,
  isLoading: isLoadingReducer,
  loginFreeze: loginFreezeReducer,
  menus: menusReducer,
  settings: settingsReducer,
  showMain: showMainReducer,
  showSub: showSubReducer,
  tempLanguage: tempLanguageReducer,
  updateOpen: updateOpenReducer,
  updating: updatingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
