import { StatusBar } from "@capacitor/status-bar";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";

export const themeColors: any = {
  blue: "#2185d0/#efefef/#111111",
  purplish: "#31169c/#efefef/#111111",
  lighterblue: "#0f4bd7/#efefef/#111111",
  greenish: "#416960/#efefef/#111111",
  pinkcraze: "#ea0183/#efefef/#111111",
  oranger: "#f45a02/#efefef/#111111",
  steel: "#6975a7/#efefef/#111111",
  beige: "#b4816e/#efefef/#111111",
  redbrown: "#7a242d/#efefef/#111111",
  salmonpink: "#FF8D9D/#111111/#111111",
  lightblue: "#abe1fb/#111155/#111155",
  blackorange: "#000000/#febb0f/#333311",
  orangeblack: "#21409A/#FFF501/#111111",
};

export const themeBackgrounds: any = {
  blue: {
    portrait:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/angryimg.png",
    landscape:
      "https://f004.backblazeb2.com/file/downloadsnetenglish/angryimg.png",
  },
  purplish: "#31169c/#efefef/#111111",
  lighterblue: "#0f4bd7/#efefef/#111111",
  greenish: "#416960/#efefef/#111111",
  pinkcraze: "#ea0183/#efefef/#111111",
  oranger: "#f45a02/#efefef/#111111",
  steel: "#6975a7/#efefef/#111111",
  beige: "#b4816e/#efefef/#111111",
  redbrown: "#7a242d/#efefef/#111111",
  salmonpink: "#FF8D9D/#111111/#111111",
  lightblue: "#abe1fb/#111155/#111155",
  blackorange: "#000000/#febb0f/#333311",
  orangeblack: "#21409A/#FFF501/#111111",
};

const Theming = (props: any) => {
  const theme = useSelector<RootState, any>((state) => state.settings.theme);

  useEffect(() => {
    const thisTheme = theme || localStorage.getItem("mycolors") || "blue";
    const parts = themeColors[thisTheme].split("/");
    let root = document.documentElement;

    root.style.setProperty("--toolbar-background", parts[0]);
    root.style.setProperty("--toolbar-text", parts[1]);
    root.style.setProperty("--toolbar-dark-text", parts[2]);

    StatusBar.setBackgroundColor({ color: parts[0] });

    let link = document.createElement("link");
    link.href = "/themes/" + thisTheme + ".css";
    link.rel = "stylesheet";
    link.id = "maintheme1";
    document.body.appendChild(link);
    let themeToBeRemoved = document.getElementById("maintheme");
    let themeParent = themeToBeRemoved?.parentNode;
    themeParent?.removeChild(themeToBeRemoved as HTMLElement);
    link.id = "maintheme";

    localStorage.setItem("mycolors", thisTheme);
  }, [theme]);

  return <React.Fragment></React.Fragment>;
};

export default Theming;
