import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  pages: [],
  id: "GrS6nJrGvXutYyQPZwvu",
  updated: 243523452345,
  name: "Mihaszna menü",
  origLang: "hu",
  restaurant: "Kisrabló Vendéglő",
};

const activeMenuSlice = createSlice({
  initialState,
  name: "activeMenu",
  reducers: {
    setActiveMenu: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setActiveMenu } = activeMenuSlice.actions;

export default activeMenuSlice.reducer;
