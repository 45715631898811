import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const tempLanguageSlice = createSlice({
  initialState: "en",
  name: "tempLanguage",
  reducers: {
    setTempLanguage: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setTempLanguage } = tempLanguageSlice.actions;

export default tempLanguageSlice.reducer;
