import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const editToolSlice = createSlice({
  initialState,
  name: "editTool",
  reducers: {
    setEditTool: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setEditTool } = editToolSlice.actions;

export default editToolSlice.reducer;
