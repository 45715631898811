//nocheck
import React, { useEffect } from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  margin-top: 0px;
  padding: 15px;
  padding-top: 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
`;

const TextDiv = styled.div`
  font-size: 18px;
  font-family: var(--font-normal);
  margin-bottom: 10px;
  line-height: 130%;
`;
const MainHeaderDiv = styled.div`
  font-size: 26px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
`;
const HeaderDiv = styled.div`
  font-size: 24px;
  font-family: var(--font-normal);
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.3;
`;

const Subscription = (props: any) => {
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <WrapperDiv>
        <MainHeaderDiv>{tt("sst1a", "Subscription Terms")}</MainHeaderDiv>
        <TextDiv>
          {tt(
            "sst2a",
            "These subscription terms (“Terms”, “Agreement”) are an agreement between NetEnglish Solutions OÜ Hungarian Permanent Establishment (“NetEnglish Solutions OÜ”, “us”, “we” or “our”) and you (“User”, “you” or “your”)."
          )}{" "}
          {tt(
            "sst3a",
            "This Agreement sets forth the general terms and conditions of your subscription on the netenglish.com website."
          )}
        </TextDiv>
        <HeaderDiv>
          {tt("st13a", "Company and contact data of Service Provider")}
        </HeaderDiv>
        <TextDiv>
          {tt(
            "t214acx",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment."
          )}
        </TextDiv>
        <TextDiv>
          {tt(
            "t214aacx",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </TextDiv>
        <TextDiv>{tt("t215acw", "*Website:* https://netenglish.com")}</TextDiv>
        <TextDiv>{tt("t216acw", "*Tax Number:* 27972984-2-18")}</TextDiv>
        <TextDiv>{tt("t217acw", "*E-mail:* support@netenglish.com")}</TextDiv>
        <TextDiv>
          {tt("t218acw", "*Company Registry Number:* 18-17-000025")}
        </TextDiv>
        <TextDiv>
          {tt(
            "t219acw",
            "*Hosted by:* Google Cloud Hosting (support@google.com)"
          )}
        </TextDiv>
        <HeaderDiv>{tt("st20a", "Subscription Terms")}</HeaderDiv>
        <TextDiv>
          {tt(
            "st21a",
            "You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable."
          )}{" "}
          {tt(
            "st21b",
            "You can make subscriptions with Visa and Mastercard cards or PayPal through third-party payment gateways."
          )}{" "}
        </TextDiv>

        <HeaderDiv>{tt("st20n", "Subscriptions")}</HeaderDiv>
        <TextDiv>
          {tt(
            "st23o",
            "If you select monthly or semiannual subscription, you will be charged automatically in accordance with the term you selected. You specifically have to agree to this by selecting these subscription services and approving your choice through our payment gateway."
          )}{" "}
          {tt(
            "st23oa",
            "We charge your approved means of payment (bank card or PayPal account) on the same day of the month each month (if you have a monthly subscription) or every six month (if you have a semiannual subscription)."
          )}{" "}
          {tt("st23oaa", "We will send you an invoice of each payment.")}{" "}
          {tt(
            "st24pa",
            "We reserve the right to change products and product pricing at any time."
          )}{" "}
          {tt(
            "s24q",
            "However we never change prices on subscriptions you already started without prior authorization from you."
          )}{" "}
          {tt(
            "st26r",
            "You can always cancel your subscription without notice under Account in the menu by clicking on the Cancel button in the Subscription panel."
          )}{" "}
          {tt(
            "st26s",
            "If you cancel your subscription you will not be charged again unless you specifically autorize us again by creating a new subscription."
          )}{" "}
          {tt(
            "sst26t",
            "If you cancel your subscription you can continue to use NetEnglish for the balance of your last subscription period."
          )}
        </TextDiv>

        <HeaderDiv>{tt("st127a", "Suspension of Subscription")}</HeaderDiv>
        <TextDiv>
          {tt(
            "st1128a",
            "If you are not on an unlimited plan you can suspend your membership once every 30 days. This means that your remaining days till the expiration of your membership or till the next billing will be preserved until you unsuspend your account."
          )}{" "}
          {tt(
            "st1129d",
            "For example if you had 20 days left till the next billing and you suspend your account for two months, then upon restarting it you will still have 20 days to go."
          )}{" "}
          {tt(
            "st1130d",
            "The next billing (if you have a running subscription) will only occur when the 20 days run out."
          )}{" "}
        </TextDiv>
        <TextDiv>
          {tt(
            "st1131d",
            "*Note:* If you suspend your account and then unsuspend it you can only suspend it again when 30 days passed since the last unsuspension."
          )}{" "}
        </TextDiv>

        <HeaderDiv>{tt("st77d", "Acceptance of these terms")}</HeaderDiv>
        <TextDiv>
          {tt(
            "st78d",
            "You acknowledge that you have read this Agreement and agree to all its terms and conditions."
          )}{" "}
          {tt(
            "st79d",
            "By subscribing to our Services you agree to be bound by this Agreement."
          )}{" "}
          {tt(
            "st80d",
            "If you do not agree to abide by the terms of this Agreement, select a different option."
          )}
        </TextDiv>
        <HeaderDiv>{tt("st81d", "Contacting us")}</HeaderDiv>
        <TextDiv>
          {tt(
            "st82d",
            "If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to support@netenglish.com"
          )}
        </TextDiv>
        <TextDiv>
          {tt("t83dxx", "This document was last updated on July 6, 2020")}
        </TextDiv>
      </WrapperDiv>
    </React.Fragment>
  );
};

export default Subscription;
