import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const updateOpenSlice = createSlice({
  initialState,
  name: "updateOpen",
  reducers: {
    setUpdateOpen: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setUpdateOpen } = updateOpenSlice.actions;

export default updateOpenSlice.reducer;
