import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "redux/reducers/_index";

import styled from "styled-components";
import { prepareFormatTerms } from "utils/globalize";

import { Device } from "@capacitor/device";

import { decodedStr } from "../../utils/utils";
import Cookies from "./legal.cookies";
import Impressum from "./legal.impresszum";
import Privacy from "./legal.privacy";
import Subscription from "./legal.subscription";
import Terms from "./legal.terms";
import Eula from "./legal.eula";
import store from "redux/store";
import { setTempLanguage } from "redux/reducers/reducer.tempLanguage";
import LegalTranslation from "./translations/translations";
import LanguageList from "admin/Login/header.language";
import { Button } from "reactstrap";

let platform = "web";

Device.getInfo().then((res) => (platform = res.platform));

const FixedButton = styled(Button)`
  position: absolute;
  bottom: 10px;
  right: 20px;
`;

const isMainWeb = document.location.href.includes("&a=1");

const LegalStandAlone = (props: any) => {
  const [termsTranslation, setTermsTranslation] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  // const language = useSelector<RootState, any>((state) => state.tempLanguage);

  const goBack = () => {
    if (platform !== "web") {
      history.goBack();
    } else {
      history.push("https://app.IntMenu.com");
    }
  };

  const language = useSelector<RootState, any>((state) => state.tempLanguage);

  console.log(language);

  const setLanguage = (lang: any) => store.dispatch(setTempLanguage(lang));

  useEffect(() => {
    const loadTermsTranslation = async () => {
      try {
        setTimeout(() => setVisible(true), 0);
      } catch (e) {
        setTimeout(() => setVisible(true), 0);
      }
    };

    if (language) {
      loadTermsTranslation();
    } else {
      setTimeout(() => setVisible(true), 0);
    }
  }, [language]);

  const tt = useCallback(
    (uuid: string, orig: string = "", avar: string = "", bvar: string = "") => {
      orig = termsTranslation[uuid || "bibibi"] || orig;
      orig = orig.replace(/xxx/g, avar);
      orig = orig.replace(/yyy/g, bvar);
      return prepareFormatTerms(decodedStr(orig));
    },
    [termsTranslation]
  );

  const getText = () => {
    switch (props.doc) {
      case "terms":
        return <Terms tt={tt} />;
      case "privacy":
        return <Privacy tt={tt} />;
      case "subscription":
        return <Subscription tt={tt} />;

      case "cookies":
        return <Cookies tt={tt} />;
      case "impress":
        return <Impressum tt={tt} />;
      case "eula":
        return <Eula tt={tt} />;
    }
  };

  return (
    <div
      style={{
        background: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        zIndex: 10000006,
      }}
    >
      <div
        id="bubu3"
        style={{
          opacity: visible ? 1 : 0,
          transition: "all 0.3s",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          paddingTop: "env(safe-area-inset-top)",
          paddingBottom: "env(safe-area-inset-bottom)",
          position: "relative",
        }}
      >
        <LegalTranslation setTermsTranslation={setTermsTranslation} />
        {getText()}
      </div>
      <LanguageList language={language} setLanguage={setLanguage} />
      {!isMainWeb ? (
        <FixedButton positive onClick={goBack} circular icon="arrow left" />
      ) : null}
    </div>
  );
};

export default LegalStandAlone;
