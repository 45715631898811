import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/_index";

//@ts-ignore
import { SendIntent } from "send-intent";

let alreadyLoaded = false;

const SendIntentSetter = (props: any) => {
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    const sendIntentHandler = async (e: any, offline: boolean = false) => {
      const result = await SendIntent.checkSendIntentReceived();

      if (!result.type) {
        return;
      }

      // store.dispatch(setShareItem({ ...result }));

      // store.dispatch(setOpenClipboard(true));

      // historyRef.current.push("/app/library?local=mobile&data=" + Date.now());
    };

    // apApp.addListener("appStateChange", sendIntentHandler);

    if (currentUser?.id && !alreadyLoaded) {
      alreadyLoaded = true;
      sendIntentHandler("", true);
    }

    window.addEventListener("sendIntentReceived", sendIntentHandler);

    return () =>
      window.removeEventListener("sendIntentReceived", sendIntentHandler);
  }, [currentUser?.id]);

  return <React.Fragment></React.Fragment>;
};

export default SendIntentSetter;
