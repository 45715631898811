import React from "react";

import {
  HomeHelpBoldDiv,
  HomeHelpHeaderDiv,
  HomeWrapperDiv,
} from "./00-styles";

export default function HelpAndroidWarn() {
  return (
    <React.Fragment>
      <HomeWrapperDiv>
        <HomeHelpHeaderDiv>
          Üdvözlünk a Gyakorlatias Angol alkalmazásában!
        </HomeHelpHeaderDiv>
        <HomeHelpBoldDiv>
          Androidos eszközökön ajánljuk, hogy a Chrome böngészőben nyisd meg az
          alkalmazásunkat. Működik más böngészőkben is, de lehet, hogy nem tudod
          kitenni a kezdőképernyőre az alkalmazás ikonját.
        </HomeHelpBoldDiv>
      </HomeWrapperDiv>
    </React.Fragment>
  );
}
