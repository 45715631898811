import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const showSubSlice = createSlice({
  initialState,
  name: "showSub",
  reducers: {
    setShowSub: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setShowSub } = showSubSlice.actions;

export default showSubSlice.reducer;
