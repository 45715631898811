import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const updatingSlice = createSlice({
  initialState,
  name: "updating",
  reducers: {
    setUpdating: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setUpdating } = updatingSlice.actions;

export default updatingSlice.reducer;
