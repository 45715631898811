import React from "react";
import {
  HomeHelpBoldDiv,
  HomeHelpHeaderDiv,
  HomeHelpImg,
  HomeHelpImgDiv,
  HomeHelpTextdDiv,
  HomeWrapperDiv,
} from "./00-styles";

export default function HelpIPhone() {
  return (
    <React.Fragment>
      <HomeWrapperDiv>
        <HomeHelpHeaderDiv>
          Üdvözlünk a Gyakorlatias Angol alkalmazásában!
        </HomeHelpHeaderDiv>
        <HomeHelpBoldDiv>
          Mielőtt bármi mást csinálnánk: Tedd ki a Gyakorlatias Angol appot a
          telefon kezdőképernyőjére.
        </HomeHelpBoldDiv>

        {/* <div className={classes.helpText}>
          Egy egy úgynevezett webalkalmazás, ami azt jelenti, hogy futtathatod
          egy egszerű böngészőből is.
        </div>
        <div className={classes.helpText}>
          Ugyanakkor a telefonodon csinálhatsz egy ikont is a kezdőképernyőn, és
          úgy futtathatod, mint bármely más alkalmazást.
        </div>
        <div className={classes.helpBold}>Ehhez tedd a következőt:</div> */}
        <HomeHelpTextdDiv>
          Kattints a Megosztás gombra a böngésződ alsó sávjában (vagy iPaden a
          felső sávban).
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="iphone1" src={`/help/iphone1.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          A megjelenő kis ablakban keresd meg azt, hogy Hozzáadás a
          főképernyőhöz, és kattints rá!
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="iphone2" src={`/help/iphone2.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Az újabb megjelenő kis ablakban kattints a Hozzáadás gombra a jobb
          felső sarokban.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="iphone3" src={`/help/iphone3.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          A böngésző be fog záródni, és látni fogod az új ikont a
          kezdőképernyődön.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="iphone4" src={`/help/iphone4.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Most már elindíthatod az alkalmazást erre az ikonra kattintva.
        </HomeHelpTextdDiv>
      </HomeWrapperDiv>
    </React.Fragment>
  );
}
