import React from "react";
import ReactHtmlParser from "html-react-parser";
import store from "redux/store";

import { decodedStr } from "./utils";

export const tx = (
  uuid: string,
  orig: string = "",
  avar: any = "",
  bvar: string = ""
) => {
  if (avar === true) {
    return orig;
  }
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  if (avar === "nbsp") {
    orig = orig.replace(/ /g, String.fromCharCode(160));
  } else {
    orig = orig.replace(/xxx/g, avar);
    orig = orig.replace(/yyy/g, bvar);
  }
  return decodedStr(orig);
};

export const ty = (
  uuid: string,
  orig: string = "",
  avar: string = "",
  bvar: string = ""
) => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  orig = orig.replace(/xxx/g, avar);
  if (orig.includes("[[[")) {
    orig = orig.replace(/\[\[\[(.*?)\]\]\]/, "<a href=" + bvar + ">$1</a>");
  }

  return prepareFormat(decodedStr(orig));
};

export const tz = (
  uuid: string,
  orig: any = "",
  avar: any = "",
  bvar: any = ""
) => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  orig = orig.replace(/xxx/g, avar);
  orig = decodedStr(orig);
  if (orig.includes("[[[")) {
    const tempOrig = orig.split(/\[\[\[|\]\]\]/);
    orig = (
      <React.Fragment>
        {tempOrig[0]}
        <span
          id={bvar || ""}
          style={{
            color: "var(--toolbar-background)",
            cursor: "pointer",
            fontWeight: "bold",
          }}
          onClick={avar}
        >
          {tempOrig[1].replace("]]]", "")}
        </span>
        {tempOrig[2]}
      </React.Fragment>
    );
  }

  return orig;
};

export const th = (
  uuid: string,
  orig: string = "",
  avar: string = "",
  bvar: string = ""
) => {
  orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  orig = orig.replace(/xxx/g, avar);
  orig = orig.replace(/yyy/g, bvar);
  return prepareFormat(decodedStr(orig));
};

export const prepareFormat = (html: string) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='bold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};

export const prepareFormatTerms = (html: string) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='termbold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};
