import React from "react";

import {
  HomeHelpBoldDiv,
  HomeHelpHeaderDiv,
  HomeHelpImg,
  HomeHelpImgDiv,
  HomeHelpTextdDiv,
  HomeWrapperDiv,
} from "./00-styles";

export default function HelpAndroidSamsung() {
  return (
    <React.Fragment>
      <HomeWrapperDiv>
        <HomeHelpHeaderDiv>
          Üdvözlünk a Gyakorlatias Angol alkalmazásában!
        </HomeHelpHeaderDiv>
        <HomeHelpBoldDiv>
          Mielőtt bármi mást csinálnánk: Tedd ki a Gyakorlatias Angol appot a
          telefon kezdőképernyőjére.
        </HomeHelpBoldDiv>

        <HomeHelpTextdDiv>
          Kattints a a böngészőben (ahol ezt olvasod) a jobb alsó sarokban lévő
          ikonra.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and1" src={`/help/sams1.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Kattints az Oldal hozzáadása opcióra a megjelenő menüben.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and2" src={`/help/sams2.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          A megjelenő kis ablakban kattints a Kezdőképernyő opcióra.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and3" src={`/help/sams3.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          A megjelenő következő ablakban kattints a Hozzáadása gombra.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and4" src={`/help/sams4.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Zárd be a böngészőt, és a Gyakorlatias Angol alkalmazás valahol ott
          lesz a kezdőképernyődön. Nézz körül, és találd meg!
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and5" src={`/help/and5.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Kattints rá az ikonra, hogy elindítsd az alkalmazást!
        </HomeHelpTextdDiv>
        <HomeHelpTextdDiv>Jó tanulást!</HomeHelpTextdDiv>
      </HomeWrapperDiv>
    </React.Fragment>
  );
}
