import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const authLoadedSlice = createSlice({
  initialState,
  name: "authLoaded",
  reducers: {
    setAuthLoaded: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setAuthLoaded } = authLoadedSlice.actions;

export default authLoadedSlice.reducer;
