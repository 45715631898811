import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/_index";
import store from "redux/store";

import { setHomeOpen } from "redux/reducers/reducer.homescreen";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function HomeDialog(props: any) {
  const home = useSelector<RootState, any>((state) => state.home);

  const closeHelp = () => {
    store.dispatch(setHomeOpen(false));
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={home.open}
        toggle={closeHelp}
        className={"modal-dialog-centered homescreen"}
      >
        <ModalHeader toggle={closeHelp}>Kezdd ezzel!</ModalHeader>
        <ModalBody style={{ overflow: "auto" }}>{home.src}</ModalBody>
      </Modal>
    </React.Fragment>
  );
}
