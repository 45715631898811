import LegalStandAlone from "app/legal/legal.standalone";
import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootState } from "redux/reducers/_index";
import { setHelpHeader } from "redux/reducers/reducer.help";
import store from "redux/store";
// import { Loader } from "semantic-ui-react";
// import styled from "styled-components";
import CustomConfirm from "utils/Confirm";

import CustomPrompt from "utils/Prompt";
import { MainDiv } from "./App.maincss";
import Header from "Header/header";

const AppUi = React.lazy(() => import("./routing/ui.app"));
const LoginUi = React.lazy(() => import("./routing/ui.login"));
// const Privacy = React.lazy(() => import("legal/legal.privacy"));
// const Terms = React.lazy(() => import("legal/legal.terms"));

// child matches will...

const Routing = React.memo(() => {
  const helpOpen = useSelector<RootState, any>((state) => state.help.open);

  const confirmOpen = useSelector<RootState, any>(
    (state) => state.confirm.open
  );

  const confirmPrompt = useSelector<RootState, any>(
    (state) => state.confirm.openPrompt
  );

  const showMain = useSelector<RootState, any>((state) => state.showMain);

  useEffect(() => {
    if (!helpOpen) {
      store.dispatch(setHelpHeader(""));
    }
  }, [helpOpen]);

  console.log("guci");

  return (
    <React.Fragment>
      <MainDiv className={showMain ? "visib" : ""}>
        <Switch>
          <Route path="/frunner">
            <div />
          </Route>

          <Route path="/login">
            <Suspense fallback={<div />}>
              <LoginUi />
            </Suspense>
          </Route>
          <Route path="/app">
            <Suspense fallback={<div />}>
              <AppUi />
            </Suspense>
          </Route>

          <Route path="/privacy">
            <LegalStandAlone doc="privacy" />
          </Route>
          <Route path="/terms">
            <LegalStandAlone doc="terms" />
          </Route>
          <Route path="/eula">
            <LegalStandAlone doc="eula" />
          </Route>
          <Route path="/impress">
            <LegalStandAlone doc="impress" />
          </Route>
          <Route path="/">
            <Redirect to="/app/main" />
          </Route>
        </Switch>
      </MainDiv>
      {confirmOpen ? <CustomConfirm /> : null}
      {confirmPrompt ? <CustomPrompt /> : null}
    </React.Fragment>
  );
});

export default Routing;

// <div className={classes.backdrop} />
