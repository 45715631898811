import styled from "styled-components";

export const HomeWrapperDiv = styled.div`
  padding: 0px;
  line-height: 150%;
  transition: all 0.15s;
  opacity: 1;
`;

export const WrapperInvisibleDiv = styled.div`
  padding: 0px;
  line-height: 150%;
  transition: all 0.15s;
  opacity: 0;
`;

export const HomeOpenDiv = styled.div`
  max-height: 3000px;
  transition: all 0.8s;
  overflow: hidden;
  padding: 10px;
  background: #eee;
  border: 1px solid lightgrey;
  margin-bottom: 10px;
  border-radius: 5px;
`;

export const HomeClosedDiv = styled.div`
  max-height: 0px;
  transition: all 0.8s;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const HomeHelpTextdDiv = styled.div`
  transition: all 0.8s;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const HomeHelpLinkOpenDiv = styled.div`
  color: blue;
  cursor: pointer;
  height: auto;
  transition: all 0.4s;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const HomeHelpLinkClosedDiv = styled.div`
  color: blue;
  cursor: pointer;
  height: 0px;
  transition: all 0.4s;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const HomeHelpListDiv = styled.div`
  font-family: var(--font-normal);
  font-size: 18px;
  padding-bottom: 10px;
  line-height: 150%;
  text-indent: -20px;
  padding-left: 20px;
`;

export const HomeHelpBoldDiv = styled.div`
  font-family: var(--font-bold);
  font-size: 18px;
  padding-bottom: 10px;
  line-height: 150%;
  font-weight: bold;
`;

export const HomeHelpHeaderDiv = styled.div`
  font-family: var(--font-normal);
  font-weight: 700;
  font-size: 21px;
  padding-top: 15px;
  padding-bottom: 20px;
  line-height: 140%;
  color: var(--toolbar-background);
`;

export const HomeHelpImg = styled.img`
  width: 100%;
  max-width: 400px;
`;

export const HomeHelpImgSmall = styled.img`
  width: 100%;
  max-width: 180px;
`;

export const HomeHelpImgDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  text-align: center;
`;
