import React from "react";
import {
  HomeHelpBoldDiv,
  HomeHelpHeaderDiv,
  HomeHelpImg,
  HomeHelpImgDiv,
  HomeHelpTextdDiv,
  HomeWrapperDiv,
} from "./00-styles";

export default function HelpAndroid() {
  return (
    <React.Fragment>
      <HomeWrapperDiv>
        <HomeHelpHeaderDiv>
          Üdvözlünk a Gyakorlatias Angol alkalmazásában!
        </HomeHelpHeaderDiv>
        <HomeHelpBoldDiv>
          Mielőtt bármi mást csinálnánk: Tedd ki a Gyakorlatias Angol appot a
          telefon kezdőképernyőjére.
        </HomeHelpBoldDiv>

        <HomeHelpTextdDiv>
          Kattints a három kis pöttyre a böngésző (ahol ezt olvasod) jobb felső
          sarkában.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and1" src={`/help/and1.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Kattints a Kezdőképernyőre opcióra a megjelenő menüben.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and2" src={`/help/and2.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          A megjelenő kis ablakban kattints a Hozzáadás gombra, amikor elérhető
          lesz.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and3" src={`/help/and3.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Ha megjelenik még egy ablak, ott is kattints a Hozzáadás gombra.
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and4" src={`/help/and4.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Zárd be a Chrome-ot, és a Gyakorlatias Angol alkalmazás valahol ott
          lesz a kezdőképernyődön. Nézz körül, és találd meg!
        </HomeHelpTextdDiv>
        <HomeHelpImgDiv>
          <HomeHelpImg alt="and5" src={`/help/and5.png`} />
        </HomeHelpImgDiv>
        <HomeHelpTextdDiv>
          Kattints rá az ikonra, hogy elindítsd az alkalmazást!
        </HomeHelpTextdDiv>
        <HomeHelpTextdDiv>Jó tanulást!</HomeHelpTextdDiv>
      </HomeWrapperDiv>
    </React.Fragment>
  );
}
