import React from "react";

import store from "redux/store";
import { setHomeSrc, setHomeOpen } from "redux/reducers/reducer.homescreen";

import HelpAndroid from "./21-homescreen_android_short";
import HelpAndroidSamsung from "./21-homescreen_android_samsung";
import HelpIPhone from "./20-homescreen_iphone";
import HelpAndroidWarn from "./21-homescreen_android_warn";
import HelpIPhoneWarn from "./20-homescreen_iphone_warn";
import { beginsWith } from "utils/utils";

const { detect } = require("detect-browser");
const browser = detect();
const standalone = window.matchMedia("(display-mode: standalone)").matches;

export const checkGoing = () => {
  if (document.location.href.includes("/newpw")) {
    return;
  }

  // if (
  //   !document.location.href.includes("szv.gyakorlatias-angol.hu") &&
  //   platform === "web" &&
  //   browser.os === "iOS"
  // ) {
  //   store.dispatch(setHelpSrc(<HelpAppIOS />));
  //   store.dispatch(setHelpOpen(true));
  //   return;
  // }

  // if (
  //   !document.location.href.includes("szv.gyakorlatias-angol.hu") &&
  //   platform === "web" &&
  //   browser.os === "Android OS"
  // ) {
  //   store.dispatch(setHelpSrc(<HelpAppAndroid />));
  //   store.dispatch(setHelpOpen(true));
  //   return;
  // }

  if (
    !standalone &&
    browser.os === "Android OS" &&
    beginsWith(browser.name, ["chrome"])
  ) {
    store.dispatch(setHomeSrc(<HelpAndroid />));
    store.dispatch(setHomeOpen(true));
    return;
  }

  if (
    !standalone &&
    browser.os === "Android OS" &&
    beginsWith(browser.name, ["samsung"])
  ) {
    store.dispatch(setHomeSrc(<HelpAndroidSamsung />));
    store.dispatch(setHomeOpen(true));
    return;
  }

  if (
    !standalone &&
    browser.os === "iOS" &&
    beginsWith(browser.name, ["ios"])
  ) {
    store.dispatch(setHomeSrc(<HelpIPhone />));
    store.dispatch(setHomeOpen(true));
    return;
  }

  if (!standalone && browser.os === "Android OS") {
    store.dispatch(setHomeSrc(<HelpAndroidWarn />));
    store.dispatch(setHomeOpen(true));
    return;
  }

  if (!standalone && browser.os === "iOS") {
    store.dispatch(setHomeSrc(<HelpIPhoneWarn />));
    store.dispatch(setHomeOpen(true));
    return;
  }
  return;
};
