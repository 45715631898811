import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const isChangedSlice = createSlice({
  initialState,
  name: "isChanged",
  reducers: {
    setIsChanged: (state: boolean, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setIsChanged } = isChangedSlice.actions;

export default isChangedSlice.reducer;
