import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const customizeSlice = createSlice({
  initialState: {} as any,
  name: "customize",
  reducers: {
    setCustomize: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setCustomizeOne: (state: any, action: PayloadAction<any>) => {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const { setCustomize, setCustomizeOne } = customizeSlice.actions;

export default customizeSlice.reducer;
