import React, { useEffect } from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  margin-top: 0px;
  padding: 15px;
  padding-top: 0px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
`;

const Cookies = (props: any) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://consent.cookiebot.com/cf6df283-b220-4082-b67f-19a49d42ffdf/cd.js";
    script.async = true;
    script.id = "CookieDeclaration";

    document.getElementById("cookiewrap")?.appendChild(script);
  }, []);

  return (
    <React.Fragment>
      <WrapperDiv id="cookiewrap"></WrapperDiv>
    </React.Fragment>
  );
};

export default Cookies;
