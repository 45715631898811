import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SettingsType {
  testReview: boolean;
  autoRead: boolean;
  pronunciation: boolean;
  writing: boolean;
  readSpeed: number;
  playSpeed: number;
  pro: boolean;
  startingLevel: string;
  showFirstPage: boolean;
  wordLevel: string;
  highlight: boolean;
  playing: boolean;
  fontSize: number;
  head?: boolean;
  theme?: any;
  trialUser?: boolean;
  hideTutorial?: boolean;
  tours?: any;
  autoTexts?: any;
  testRepetitions?: any;
  exeRepetitions?: any;
  teachers?: any;
  startTime?: any;
  endTime?: any;
  dics?: any;
  [key: string]: any;
}

export const initialState: SettingsType = {
  testReview: false,
  autoRead: true,
  pronunciation: true,
  writing: true,
  readSpeed: 1,
  playSpeed: 1,
  pro: false,
  startingLevel: "A",
  showFirstPage: true,
  wordLevel: "A",
  highlight: true,
  playing: true,
  fontSize: 18,
  head: true,
  theme: "blue",
  trialUser: false,
  hideTutorial: false,
  tours: {},
  autoTexts: [],
  testRepetitions: 5,
  exeRepetitions: 3,
  dics: ["en001", "en002", "en003", "en004", "en005"],
  status: { status: "books" },
};

const settingsSlice = createSlice({
  initialState,
  name: "settings",
  reducers: {
    setSettings: (state: SettingsType, action: PayloadAction<SettingsType>) => {
      return action.payload;
    },
    setSettingsTestReview: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.testReview = action.payload;
    },
    setSettingsIsHuawei: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.isHuawei = action.payload;
    },
    setSettingsHead: (state: SettingsType, action: PayloadAction<boolean>) => {
      state.head = action.payload;
    },
    setSettingsAutoRead: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.autoRead = action.payload;
    },
    setSettingsPronunciation: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.pronunciation = action.payload;
    },
    setSettingsWriting: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.writing = action.payload;
    },
    setShowFirstPage: (state: SettingsType, action: PayloadAction<boolean>) => {
      state.showFirstPage = action.payload;
    },
    setPro: (state: SettingsType, action: PayloadAction<boolean>) => {
      state.pro = action.payload;
    },
    setSettingsStartingLevel: (
      state: SettingsType,
      action: PayloadAction<string>
    ) => {
      state.startingLevel = action.payload;
    },
    setSettingsReadSpeed: (
      state: SettingsType,
      action: PayloadAction<number>
    ) => {
      state.readSpeed = action.payload;
    },
    setSettingsPlaySpeed: (
      state: SettingsType,
      action: PayloadAction<number>
    ) => {
      state.playSpeed = action.payload;
    },
    setSettingsTheme: (state: SettingsType, action: PayloadAction<any>) => {
      state.theme = action.payload;
    },
    setSettingsWordLevel: (
      state: SettingsType,
      action: PayloadAction<string>
    ) => {
      state.wordLevel = action.payload;
    },
  },
});

export const {
  setSettingsWordLevel,
  setSettings,
  setSettingsTestReview,
  setSettingsAutoRead,
  setSettingsIsHuawei,
  setSettingsPronunciation,
  setSettingsWriting,
  setSettingsPlaySpeed,
  setSettingsReadSpeed,
  setPro,
  setShowFirstPage,
  setSettingsTheme,
} = settingsSlice.actions;

export default settingsSlice.reducer;
