//student
//tx("asdfasdf1misc","===Misc")
import React from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { RootState } from "redux/reducers/_index";
import { setConfirmOpen } from "redux/reducers/reducer.confirm";
import store from "redux/store";
import styled from "styled-components";

import { tx } from "./globalize";

const TextDiv = styled.div`
  font-size: 16px;
  padding: 10px;
`;

const CustomConfirm = (props: any) => {
  const confirm = useSelector<RootState, any>((state) => state.confirm);
  const help = useSelector<RootState, any>((state) => state.help);

  const clickCancel = () => {
    if (confirm.onCancel) {
      confirm.onCancel();
    }
    store.dispatch(setConfirmOpen(false));
  };

  const clickOk = () => {
    store.dispatch(setConfirmOpen(false));
    if (confirm.onConfirm) {
      confirm.onConfirm();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={confirm.open}
        toggle={clickCancel}
        className={
          "modal-dialog-centered prompter" + (help.open ? " showhelp" : "")
        }
      >
        <ModalHeader toggle={clickCancel}>
          {confirm.header || tx("64nwJUg6iL_EXmtd", "Confirmation...")}
        </ModalHeader>
        <ModalBody className="pt-1">
          <TextDiv>
            {confirm.content || tx("PEgiipg_dbm7liZO", "Are you sure?")}{" "}
          </TextDiv>
        </ModalBody>
        <ModalFooter style={{ zIndex: 0 }}>
          {confirm.cancelButton ? (
            <Button color="secondary" onClick={clickCancel}>
              {confirm.cancelButton || tx("cancel", "Cancel")}
            </Button>
          ) : null}
          {confirm.confirmButton ? (
            <Button
              color="white"
              className="bg-toolbar text-toolbar"
              onClick={clickOk}
            >
              {confirm.confirmButton || tx("ok", "OK")}
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CustomConfirm;
