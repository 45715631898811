import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const confirmSlice = createSlice({
  initialState: { open: false } as any,
  name: "confirm",
  reducers: {
    setConfirm: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    setConfirmOpen: (state: any, action: PayloadAction<boolean>) => {
      state.open = action.payload;
      state.openPrompt = action.payload;
    },
    setConfirmOpen2: (state: any, action: PayloadAction<boolean>) => {
      state.openPrompt2 = action.payload;
    },
    setConfirmDefault: (state: any, action: PayloadAction<string>) => {
      state.default = action.payload;
    },
  },
});

export const {
  setConfirm,
  setConfirmDefault,
  setConfirmOpen,
  setConfirmOpen2,
} = confirmSlice.actions;

export default confirmSlice.reducer;
