import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

const editedFileSlice = createSlice({
  initialState,
  name: "editedFile",
  reducers: {
    setEditedFile: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setEditedFile } = editedFileSlice.actions;

export default editedFileSlice.reducer;
