import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "redux/reducers/_index";
import Dimmer from "utils/Dimmer";
import { tx } from "utils/globalize";

const GlobalLoader = (props: any) => {
  const globalLoading = useSelector<RootState, any>(
    (state) => state.globalLoading
  );
  return (
    <React.Fragment>
      <Dimmer
        active={globalLoading}
        label={props.label || tx("Processing...", "Processing")}
      ></Dimmer>
    </React.Fragment>
  );
};

export default GlobalLoader;
