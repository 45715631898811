//student
// import { getProfileUrl } from "account/account.avatar";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState } from "redux/reducers/_index";
import { setConfirm } from "redux/reducers/reducer.confirm";
import { setCurrentUser, UserType } from "redux/reducers/reducer.currentUser";
import store from "redux/store";
import { tx } from "utils/globalize";
// import { setUserGlobal } from "utils/setUser";
// import { setInviteOpen } from "redux/reducers/reducer.inviteOpen";
import { beginsWith, phaseBodyOut } from "utils/utils";

import { removePushRegistration, setupPushNotification } from "./App.push";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "firebaseUtil/firebaseUtil";
import { platform } from "./App";

// const checkTutorial = () => {
//   if (!store.getState().settings.hideTutorial) {
//     store.dispatch(setTutorial(true));
//   }
// };

const AppStart = (props: any) => {
  const location = useLocation();
  const history = useHistory();

  // const posHistory = useRef<any>();

  // const theme = useSelector<RootState, any>((state) => state.settings.theme);

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const authLoaded = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  useEffect(() => {
    const b = location.search;

    if (location.pathname.includes("/app/link")) {
      return;
    }

    const code = decodeURIComponent(
      b?.match(/code=[^&]*/)?.[0].replace("code=", "") || ""
    );
    const state = decodeURIComponent(
      b?.match(/state=[^&]*/)?.[0].replace("state=", "") || ""
    );

    const stateParts = state?.split("xxxxxxxxxxxxxxxxxxxxxxx");

    if (!(stateParts[2] === "library") || !code) {
      localStorage.setItem("tempcustom", "");
    }

    if (b.includes("error=access_denied")) {
      history.push("/app/" + stateParts[2]);
      store.dispatch(
        setConfirm({
          confirmButton: tx("ok", "OK"),
          header: tx("tdpv7yPTImcgNye_", "Permission Denied..."),
          content: (
            <React.Fragment>
              <div>
                Permission was denied. Please try again and make sure you give
                access where requested.
              </div>
            </React.Fragment>
          ),
          open: true,
        })
      );

      return;
    }

    if (code) {
      const pname = location.pathname;

      const p = pname.substr(pname.indexOf("/app/main"));

      localStorage.setItem(
        "dotempcustom",
        localStorage.getItem("tempcustom") || ""
      );

      // history.push(p.replace("library", stateParts[2]));

      // getDriveTokenFromCode(code, stateParts?.[0] || "", stateParts?.[1] || "");
    }
  }, [location, history]);

  useEffect(() => {
    if (currentUser?.id && platform !== "web") {
      // setupAppPay(currentUser?.id);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (currentUser?.expiry) {
      // alert("bigi");
      // const product = InAppPurchase2.get("IntMenumonthly2");
      // store.dispatch(setUserStatus(updateProduct(product)));
    }
  }, [currentUser?.expiry, currentUser?.payPlatform, currentUser?.renewal]);

  useEffect(() => {
    if (currentUser) {
      setupPushNotification(currentUser);
    } else {
      removePushRegistration(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!authLoaded) {
      return;
    }

    if (beginsWith(location.pathname, "/login/login")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/newpw")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/forgotten")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/app")) {
      if (
        currentUser
        // (currentUser.type === "admin" ||
        //   currentUser.type === "contributor" ||
        //   currentUser.type === "voice" ||
        //   currentUser.type === "subadmin")
      ) {
        //
        return;
      } else {
        phaseBodyOut();

        setTimeout(() => history.push("/login/login"), 150);

        return;
      }
    }
  }, [location, history, currentUser, authLoaded]);

  return <React.Fragment></React.Fragment>;
};

export default AppStart;
